
					@import './src/sass/variables';
					@import './src/sass/mixins';
				





































































.clear-files::v-deep {
	button {
		padding: 0;
	}

	span {
		font-weight: $font__normal;
	}
}
